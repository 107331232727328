import React from 'react';
import { useTranslation } from 'react-i18next';

import useSubscription from '@util/hooks/useSubscription';
import Go from '@util/CustomRedirect';
import SEO from '@components/seo';
import PageHeader from '@ui/PageHeader';
import ProtectedPage from '@components/ProtectedPage';
import PaymentDetailsForm from '@components/PaymentDetails/PaymentDetailsForm';

const PaymentDetails = () => {
  const { t } = useTranslation();
  const pageTitle = t('page:modifyPaymentDetails');

  return (
    <ProtectedPage>
      <Rules>
        <SEO title={pageTitle} />

        <div className="row mb-4">
          <div className="col-12">
            <PageHeader>{pageTitle}</PageHeader>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-xl-8">
            <PaymentDetailsForm hidePaymentMethod />
          </div>
        </div>
      </Rules>
    </ProtectedPage>
  );
};

function Rules({ children }) {
  const subscription = useSubscription();
  const homeRules =
    !subscription.isSubscriber || subscription.isActiveSubscriber === false;
  return (
    <Go to="/" when={homeRules}>
      {children}
    </Go>
  );
}

export default PaymentDetails;
